import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly apiUrl = environment.apiUrl;
  private readonly loginUrl = `${this.apiUrl}/usuarios/login`;
  private readonly logoutUrl = `${this.apiUrl}/usuarios/logout`;
  private readonly refreshTokenUrl = `${this.apiUrl}/jwt/refresh-token`;
  private readonly accessToken = "AccessToken";

  constructor(private readonly http: HttpClient, private readonly router: Router) {
  }

  login(credentials: { correo: string, contrasena: string }): Observable<{ accessToken: string }> {
    return this.http.post<{ accessToken: string }>(this.loginUrl, credentials, {withCredentials: true}).pipe(
      tap((response: any) => {
        if (response?.accessToken) {
          this.setAccessToken(response.accessToken);
        }
      })
    );
  }

  logout(): void {
    this.http.post(this.logoutUrl, {}, {withCredentials: true, responseType: 'text'}).subscribe({
      next: () => {
        localStorage.removeItem(this.accessToken);
        this.router.navigate(['/login']);
      },
      error: (error) => {
        console.error('Error during logout:', error);
      },
    });
  }

  setAccessToken(token: string): void {
    localStorage.setItem(this.accessToken, token);
  }

  getAccessToken(): string | null {
    const token = localStorage.getItem(this.accessToken);
    return token;
  }

  refreshAccessToken(): Observable<{ accessToken: string }> {
    return this.http.post<{ accessToken: string }>(this.refreshTokenUrl, {}, {withCredentials: true}).pipe(
      tap(response => {
        if (response?.accessToken) {
          this.setAccessToken(response.accessToken);
        }
      }),
      catchError((error) => {
        console.error('Error refreshing token:', error);
        this.logout();
        return throwError(() => error);
      })
    );
  }

  isAuthenticated(): boolean {
    const token = this.getAccessToken();
    if (!token) {
      return false;
    }

    // Decodificamos el Token que esta en Base64
    // El token tiene este formato [0 -> Header, 1 -> Payload, 2 -> Signature]
    const payload = JSON.parse(atob(token.split('.')[1]));
    // Expiracion del token
    const exp = payload.exp * 1000;
    return Date.now() < exp;
  }
}
